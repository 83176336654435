<template>
  <home-blocks-wrapper
    id="interview-questions"
    :title="LL.question()"
    :highlight-title="LL.interview()"
    link-view-more="/phong-van-pro/full-stack-web-and-mobile/java"
  >
    <div class="flex items-center gap-1 mb-4">
      <UBadge size="lg" color="neutral" variant="subtle">{{ questionsCount }}</UBadge>
      <span class="text-sm sm:text-base text-neutral-500 first-letter:uppercase">{{
        LL.interview_question_description()
      }}</span>
    </div>
    <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
      <InterviewQuestionsItem
        v-for="block in interviewBlocks"
        :key="block.domain"
        :domain="block.domain"
        :icon-name="block.iconName"
        :link-go-to="block.linkGoTo"
      />
    </div>
  </home-blocks-wrapper>
</template>

<script setup lang="ts">
import InterviewQuestionsItem from "./InterviewQuestionItem.vue";
import { typesafeI18n } from "~/locales/i18n-vue";
import { useUserStore } from "~/store";

type Props = {
  questionsCount: number;
};
defineProps<Props>();
const { LL } = typesafeI18n();
const userStore = useUserStore();

const interviewBlocks = computed(() => {
  if (userStore.isGlobalMarket) {
    return [
      {
        domain: "Java",
        iconName: "i-simple-icons-coffeescript",
        linkGoTo: "/phong-van-pro/full-stack-web-and-mobile-global/java-global",
      },
      {
        domain: "Javascript",
        iconName: "i-simple-icons-javascript",
        linkGoTo: "/phong-van-pro/full-stack-web-and-mobile-global/javascript-global",
      },
      {
        domain: "Python",
        iconName: "i-simple-icons-python",
        linkGoTo: "/phong-van-pro/full-stack-web-and-mobile-global/python-global",
      },
      {
        domain: "Nodejs",
        iconName: "i-simple-icons-nodedotjs",
        linkGoTo: "/phong-van-pro/full-stack-web-and-mobile-global/nodejs-global",
      },
      {
        domain: "Golang",
        iconName: "i-simple-icons-go",
        linkGoTo: "/phong-van-pro/full-stack-web-and-mobile-global/golang-global",
      },
      {
        domain: "Spring",
        iconName: "i-simple-icons-spring",
        linkGoTo: "/phong-van-pro/full-stack-web-and-mobile-global/spring-global",
      },
      {
        domain: "Reactjs",
        iconName: "i-simple-icons-react",
        linkGoTo: "/phong-van-pro/full-stack-web-and-mobile-global/reactjs-global",
      },
      {
        domain: "DevOps",
        iconName: "i-simple-icons-docker",
        linkGoTo: "/phong-van-pro/full-stack-web-and-mobile/devops",
      },
      {
        domain: "Blockchain",
        iconName: "i-simple-icons-codeblocks",
        linkGoTo: "/phong-van-pro/coding-and-data-structures-global/blockchain-global",
      },
      {
        domain: "Microservices",
        iconName: "i-simple-icons-metro",
        linkGoTo:
          "/phong-van-pro/system-design-and-architecture-global/microservices-global",
      },
      {
        domain: "Angular",
        iconName: "i-simple-icons-angular",
        linkGoTo: "/phong-van-pro/full-stack-web-and-mobile-global/angular-global",
      },
      {
        domain: "MySQL",
        iconName: "i-simple-icons-mysql",
        linkGoTo: "/phong-van-pro/full-stack-web-and-mobile-global/mysql-global",
      },
    ];
  } else if (userStore.isVietnamMarket) {
    return [
      {
        domain: "Java",
        iconName: "i-simple-icons-coffeescript",
        linkGoTo: "/phong-van-pro/full-stack-web-and-mobile/java",
      },
      {
        domain: "Javascript",
        iconName: "i-simple-icons-javascript",
        linkGoTo: "/phong-van-pro/full-stack-web-and-mobile/javascript",
      },
      {
        domain: "Python",
        iconName: "i-simple-icons-python",
        linkGoTo: "/phong-van-pro/full-stack-web-and-mobile/python",
      },
      {
        domain: "Nodejs",
        iconName: "i-simple-icons-nodedotjs",
        linkGoTo: "/phong-van-pro/full-stack-web-and-mobile/nodejs",
      },
      {
        domain: "Golang",
        iconName: "i-simple-icons-go",
        linkGoTo: "/phong-van-pro/full-stack-web-and-mobile/golang",
      },
      {
        domain: "Spring",
        iconName: "i-simple-icons-spring",
        linkGoTo: "/phong-van-pro/full-stack-web-and-mobile/spring",
      },
      {
        domain: "Reactjs",
        iconName: "i-simple-icons-react",
        linkGoTo: "/phong-van-pro/full-stack-web-and-mobile/reactjs",
      },
      {
        domain: "DevOps",
        iconName: "i-simple-icons-docker",
        linkGoTo: "/phong-van-pro/full-stack-web-and-mobile/devops",
      },
      {
        domain: "Blockchain",
        iconName: "i-simple-icons-codeblocks",
        linkGoTo: "/phong-van-pro/coding-and-data-structures/blockchain",
      },
      {
        domain: "Microservices",
        iconName: "i-simple-icons-metro",
        linkGoTo: "/phong-van-pro/system-design-and-architecture/microservices",
      },
      {
        domain: "Trees",
        iconName: "i-simple-icons-treehouse",
        linkGoTo: "/phong-van-pro/coding-and-data-structures/trees",
      },
      {
        domain: "SQL",
        iconName: "i-simple-icons-sqlite",
        linkGoTo: "/phong-van-pro/full-stack-web-and-mobile/sql",
      },
    ];
  } else {
    return [
      {
        domain: "SSL101c",
        iconName: "i-heroicons-academic-cap-20-solid",
        linkGoTo: "/phong-van-pro/fpt/ssl101c",
      },
      {
        domain: "CSI104",
        iconName: "i-heroicons-academic-cap-20-solid",
        linkGoTo: "/phong-van-pro/fpt/csi104",
      },
      {
        domain: "PRO192",
        iconName: "i-heroicons-academic-cap-20-solid",
        linkGoTo: "/phong-van-pro/fpt/pro192",
      },
      {
        domain: "PRF192",
        iconName: "i-heroicons-academic-cap-20-solid",
        linkGoTo: "/phong-van-pro/fpt/prf192",
      },
      {
        domain: "MAD101",
        iconName: "i-heroicons-academic-cap-20-solid",
        linkGoTo: "/phong-van-pro/fpt/mad101",
      },
      {
        domain: "OSG202",
        iconName: "i-heroicons-academic-cap-20-solid",
        linkGoTo: "/phong-van-pro/fpt/osg202",
      },
      {
        domain: "MKT101",
        iconName: "i-heroicons-academic-cap-20-solid",
        linkGoTo: "/phong-van-pro/fpt/mkt101",
      },
      {
        domain: "MAE101",
        iconName: "i-heroicons-academic-cap-20-solid",
        linkGoTo: "/phong-van-pro/fpt/mae101",
      },
    ];
  }
});
</script>
